























































































































































































import Vue from 'vue';
import I18nSelectLocale from '@/components/i18n/SelectLocaleList.vue';
import { FOOTER_AUDIENCES } from '@/data/audience';

export default Vue.extend({
  name: 'AppFooter',
  components: {
    I18nSelectLocale,
  },
  computed: {
    audiences() {
      return FOOTER_AUDIENCES;
    },
  },
});
