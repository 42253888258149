/* eslint-disable @typescript-eslint/no-unused-vars */
export type AudienceSlug = string;
export type AudienceNeeds = (undefined | 0 | 1)[];
export type AudienceInfo = [AudienceNeeds, AudienceSlug[]];
export type AudienceInfoObject = {
  needs: {
    shippingGoods: boolean,
    recurringBilling: boolean,
    professionalService: boolean,
    escrowFunds: boolean,
    largeFileStorage: boolean,
  },
  seeAlso: string[],
};

export function destructure([needs, seeAlso]: AudienceInfo): AudienceInfoObject {
  const [
    largeFileStorage,
    shippingGoods,
    recurringBilling,
    escrowFunds,
    professionalService,
  ] = needs;

  return {
    needs: {
      shippingGoods: !!shippingGoods,
      recurringBilling: !!recurringBilling,
      professionalService: !!professionalService,
      escrowFunds: !!escrowFunds,
      largeFileStorage: !!largeFileStorage,
    },
    seeAlso,
  };
}

/**
 * This will turn out extremely compact in webpack
 * - Unused consts will be dropped
 * - Slugs and needs are defined once and reused
 *
 * Example (one audience)
 * ```js
 * Object(c["a"])(i,p,[[u,f,l,r],d])
 * ```
 *
 * While this might be premature optimization,
 * this is significantly smaller than defining full
 * Objects like { seeAlso: string[], features: { feature1: boolean, ... } }
 */

// eslint-disable-next-line one-var
const
  // ! 0x: "regular" artists

  // ! 00: Digital artists
  A_00_00_DIGITAL_ARTIST = 'digital-artists',
  A_00_01_ILLUSTRATOR = 'illustrators',
  A_00_02_EXHIBITION_ARTIST = 'exhibition-artists', // physical art, not on canvas or similar
  A_00_03_TEXTURE_ARTIST = 'texture-artists',
  A_00_04_PAINT_ARTIST = 'paint-artists',

  // ! 01: Designers
  A_01_00_LOGO_DESIGNER = 'logo-designers',
  A_01_01_GRAPHIC_DESIGNER = 'graphic-designers',
  A_01_02_BRAND_DESIGNER = 'brand-designers',
  A_01_03_FASHION_DESIGNER = 'fashion-designers',
  A_01_04_ADVERTISING_DESIGNER = 'ad-designers',

  // ! 02: Artisans
  A_02_00_SCULPTOR = 'sculptors',
  A_02_01_CARPENTER = 'carpenters',
  A_02_02_TAILOR = 'tailors',
  A_02_03_PIN_MAKER = 'pin-makers',
  A_02_04_STICKER_MAKER = 'sticker-makers',
  // * rigger, plasterer

  // ! 03: Painters
  A_03_00_PORTRAIT_PAINTER = 'portrait-painters',
  A_03_01_LANDSCAPE_PAINTER = 'landscape-painters',

  // ! 10-29: Musicians

  // ! 30: Musicians
  A_10_00_SINGER = 'singers',
  A_10_01_COMPOSER = 'composers',
  A_10_02_SONG_WRITER = 'song-writers',
  A_10_03_ORCHESTRATOR = 'orchestrators',
  A_10_04_ORCHESTRA = 'orchestras',
  A_10_05_CHOIR = 'choirs',
  A_10_06_MIXER = 'sound-mixers',
  A_10_07_SYNTH_PROGRAMMER = 'synth-programmers',
  // TODO: Add specific instruments / groups
  // * foley mixer? a.d.r. recordist? sound editor? audio engineer?
  // * boom-operator? sound designer?

  /** @see https://en.wikipedia.org/wiki/Hornbostel–Sachs */

  // ! 11: Struck Idiophones
  // ! 12: Plucked Idiophones
  // ! 13: Friction Idiophones
  // ! 14: Blown Idiophones
  // ! 15: Other Idiophones
  // ! 16: Struck Membranophones
  A_16_00_DRUMMER = 'drummers',
  // ! 17: Plucked Membranophones
  // ! 18: Friction Membranophones
  // ! 19: Singing Membranes
  A_19_00_KAZOO = 'kazoos',
  // ! 20: Other Membranophones
  // ! 21: Simple Chordophones
  A_21_00_PIANIST = 'pianists',
  // ! 22: Composite Chordophones
  A_22_00_LUTE = 'lutes',
  A_22_01_VIOLINIST = 'violinists',
  A_22_02_GUITARIST = 'guitarists',
  A_22_03_HARPIST = 'harpists',
  // ! 23: Other Chordophones
  // ! 24: Free Aerophones
  A_24_00_ACCORDIAN = 'accordions',
  A_24_01_HARMONICA = 'harmonicas',
  A_24_02_ORGANIST = 'organists',
  // ! 25: Non-free Aerophones
  A_25_00_FLUTIST = 'flutists',
  A_25_01_HORNIST = 'hornists',
  A_25_02_WHISTLER = 'whistlers',
  A_25_03_RECODERS = 'recorders',
  A_25_04_OCARINA = 'ocarinas',
  A_25_05_OBOE = 'oboes',
  A_25_06_BASSOONIST = 'bassoonist',
  A_25_07_CLARINETIST = 'clarinetists',
  A_25_08_SAXOPHONIST = 'saxophonists',
  A_25_09_DIDGERIDOO = 'didgeridoo',
  A_25_10_NATURAL_HORN = 'natural-horn',
  A_25_11_TROMBONE = 'trombonist',
  A_25_12_FRENCH_HORN = 'french-horn',
  A_25_13_EUPHONIUM = 'euphonium',
  A_25_14_BARITONE_HORN = 'baritone-horn',
  A_25_15_TRUMPET = 'trumpet',
  A_25_16_TUBA = 'tuba',
  A_25_17_SAXHORN = 'saxhorn',
  // ! 26: Other Aerophones
  // ! 27: Electrophones

  // ! 28: Singers
  A_29_00_ACAPELLA = 'acapella',
  // ! 29: Other Music-related

  // ! 30: Developers
  A_30_00_APP_DEVELOPER = 'app-developers',
  A_30_01_SEO_CONSULTANT = 'seo-consultants',
  A_30_02_SOFTWARE_DEVELOPER = 'software-developers',
  A_30_03_WEB_DESIGNER = 'web-designers',
  A_30_04_WEB_DEVELOPER = 'web-developers',

  // ! 4x: Films, Plays, etc.

  // ! 40: On Stage
  A_40_00_COSTUME_DESIGNER = 'custome-designers',
  A_40_01_SCREENPLAY_WRITER = 'screenplay-writers',
  A_40_02_SET_DECORATOR = 'set-decorators',
  A_40_03_MAKEUP = 'makeup-artists',
  A_40_04_HAIR = 'hair-stylists',
  A_40_05_SFX_MAKEUP = 'special-effects-makeup-artists',
  A_40_06_PROP_MODELER = 'prop-modelers',
  A_40_07_FLORIST = 'florists',
  A_40_08_DIGITAL_COMPOSITOR = 'digital-compositors',
  A_40_09_DRONE_PILOT = 'drone-pilots',
  A_40_10_COSTUMER = 'movie-costumers', // ! not customer :)

  // ! 41: Movie
  A_41_00_MOVIE_ACTOR = 'movie-actors',
  A_41_01_MOVIE_EXTRA = 'movie-extras',
  A_41_03_CINEMATOGRAPHER = 'cinematographers',
  A_41_04_MOVIE_EDITOR = 'movie-editors',
  A_41_05_MOVIE_CASTING = 'movie-casting',
  A_41_06_LOCATION_SCOUT = 'location-scouts', // location-marshalls?
  A_41_07_CAMERA_OPERATOR = 'camera-operators',

  // ! 42: Art
  A_42_00_ANIMATOR = 'animators',
  A_42_01_MOTION_CAPTURE = 'motion-capture-actors',
  A_42_02_SFX_ARTIST = 'special-effects-artists',
  A_42_03_STORYBOARD_ARTIST = 'storyboard-artists',
  A_42_04_CONCEPT_ARTIST = 'concept-artists',
  A_42_05_VFX_ARTIST = 'visual-effects-artists',
  A_42_06_CHARACTER_ARTIST = 'character-artists',
  A_42_07_COLOURIST = 'colourists',
  A_42_08_CG_ARTIST = 'cg-artists',
  A_42_09_LIGHTING_ARTIST = 'lighting-artists',
  A_42_10_LAYOUT_ARTIST = 'layout-artists',

  // ! 43: Voice & Sound
  A_43_00_VOICE_ACTOR = 'voice-actors',

  // ! 44: Movie Direction
  A_44_00_MOVIE_DIRECTOR = 'movie-directors',
  A_44_01_MOVIE_ART_DIRECTOR = 'movie-art-directors',
  A_44_02_MOVIE_PRODUCTION_MANAGER = 'movie-production-managers',

  // ! 45: Theater
  A_45_00_PLAY_ACTOR = 'play-actors',

  // * greensman? lighting td? stunts? gaffer? script-continuity-supervisor?

  // TODO: Professional Services
  // ! 9x: Professions

  // ! 90: Personal
  A_90_00_HAIRDRESSER = 'hairdressers',

  // ! 91: Outside
  A_91_00_LANDSCAPER = 'landscapers',
  A_91_01_GARDENER = 'gardeners',
  A_91_02_CONSTRUCTION = 'constructors',

  // ! 92: Inside
  A_92_00_ELECTRICIAN = 'electricians',
  A_92_01_PLUMBER = 'plumbers',

  // ! 93: Office
  A_93_00_LAWYER = 'lawyers',
  A_93_01_ARCHITECT = 'architects',

  // ! 99: Other Professions

  // TODO: Transportation? (Bus, Taxi, Truck, Train, Plane, ...?)

  // TODO: Writers (books, magazines, news?)

  // may omit 0, as !!0 and !!undefined both are falsey
  NEEDS__NONE: AudienceNeeds = [],
  NEEDS__LFS: AudienceNeeds = [1], // eslint-disable-line no-sparse-arrays
  NEEDS__SHIPPING: AudienceNeeds = [, 1], // eslint-disable-line no-sparse-arrays
  // [0] large file storage
  // [1] shipping goods
  // [2] recurring billing
  // [3] escrow funds
  // [4] professional service (on-location)

  audiences: { [key: string]: AudienceInfo } = {
    [A_00_00_DIGITAL_ARTIST]: [
      NEEDS__NONE, [
        A_00_01_ILLUSTRATOR,
        A_01_01_GRAPHIC_DESIGNER,
        A_03_00_PORTRAIT_PAINTER,
        A_42_00_ANIMATOR,
      ],
    ],
    [A_00_01_ILLUSTRATOR]: [
      NEEDS__NONE, [
        A_00_00_DIGITAL_ARTIST,
        A_01_00_LOGO_DESIGNER,
        A_01_01_GRAPHIC_DESIGNER,
      ],
    ],
    [A_01_00_LOGO_DESIGNER]: [
      NEEDS__NONE, [
        A_00_00_DIGITAL_ARTIST,
        A_00_01_ILLUSTRATOR,
        A_01_01_GRAPHIC_DESIGNER,
      ],
    ],
    [A_01_01_GRAPHIC_DESIGNER]: [
      NEEDS__LFS, [
        A_00_00_DIGITAL_ARTIST,
        A_00_01_ILLUSTRATOR,
        A_01_00_LOGO_DESIGNER,
      ],
    ],
    [A_03_00_PORTRAIT_PAINTER]: [
      NEEDS__SHIPPING, [
        A_00_00_DIGITAL_ARTIST,
      ],
    ],
    [A_10_00_SINGER]: [
      NEEDS__NONE, [
        A_10_01_COMPOSER,
        A_10_02_SONG_WRITER,
        A_21_00_PIANIST,
        A_22_02_GUITARIST,
      ],
    ],
    [A_10_01_COMPOSER]: [
      NEEDS__NONE, [
        A_10_00_SINGER,
        A_10_02_SONG_WRITER,
        A_21_00_PIANIST,
        A_22_01_VIOLINIST,
      ],
    ],
    [A_10_02_SONG_WRITER]: [
      NEEDS__NONE, [
        A_10_00_SINGER,
        A_10_01_COMPOSER,
        A_22_02_GUITARIST,
      ],
    ],
    [A_21_00_PIANIST]: [
      NEEDS__NONE, [
        A_10_00_SINGER,
        A_10_01_COMPOSER,
        A_22_01_VIOLINIST,
        A_22_02_GUITARIST,
      ],
    ],
    [A_22_01_VIOLINIST]: [
      NEEDS__NONE, [
        A_10_01_COMPOSER,
        A_21_00_PIANIST,
      ],
    ],
    [A_22_02_GUITARIST]: [
      NEEDS__NONE, [
        A_10_00_SINGER,
        A_10_02_SONG_WRITER,
        A_21_00_PIANIST,
      ],
    ],
    // [A_30_00_APP_DEVELOPER]: [
    //   NEEDS__NONE, [
    //     A_30_01_SEO_CONSULTANT,
    //     A_30_02_SOFTWARE_DEVELOPER,
    //     A_30_03_WEB_DESIGNER,
    //     A_30_04_WEB_DEVELOPER,
    //   ],
    // ],
    // [A_30_01_SEO_CONSULTANT]: [
    //   NEEDS__NONE, [
    //     A_30_00_APP_DEVELOPER,
    //     A_30_02_SOFTWARE_DEVELOPER,
    //     A_30_03_WEB_DESIGNER,
    //     A_30_04_WEB_DEVELOPER,
    //   ],
    // ],
    // [A_30_02_SOFTWARE_DEVELOPER]: [
    //   NEEDS__NONE, [
    //     A_30_00_APP_DEVELOPER,
    //     A_30_01_SEO_CONSULTANT,
    //     A_30_03_WEB_DESIGNER,
    //     A_30_04_WEB_DEVELOPER,
    //   ],
    // ],
    // [A_30_03_WEB_DESIGNER]: [
    //   NEEDS__NONE, [
    //     A_01_00_LOGO_DESIGNER,
    //     A_01_01_GRAPHIC_DESIGNER,
    //     A_30_01_SEO_CONSULTANT,
    //     A_30_04_WEB_DEVELOPER,
    //   ],
    // ],
    // [A_30_04_WEB_DEVELOPER]: [
    //   NEEDS__NONE, [
    //     A_30_00_APP_DEVELOPER,
    //     A_30_01_SEO_CONSULTANT,
    //     A_30_02_SOFTWARE_DEVELOPER,
    //     A_30_03_WEB_DESIGNER,
    //   ],
    // ],
    [A_42_00_ANIMATOR]: [
      NEEDS__LFS, [
        A_00_00_DIGITAL_ARTIST,
        A_00_01_ILLUSTRATOR,
        A_43_00_VOICE_ACTOR,
      ],
    ],
    [A_43_00_VOICE_ACTOR]: [
      NEEDS__NONE, [
        A_10_00_SINGER,
        A_42_00_ANIMATOR,
      ],
    ],
  };

export const FOOTER_AUDIENCES = [
  A_00_00_DIGITAL_ARTIST,
  A_42_00_ANIMATOR,
  A_10_02_SONG_WRITER,
  A_43_00_VOICE_ACTOR,
];

export default audiences;
